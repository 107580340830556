<template>
  <!-- 用户最近阅读 -->
  <div class="read">
    <!-- <article-list v-if="readList.length > 0" :honDocuList="readList" /> -->
    <div class="comtent_box" v-for="(item,i) in readList" :key="i">
      <!-- <p>{{item.day}}</p> -->
      <el-alert :title="'阅读时间 '+ item.day" type="info" show-icon :closable="false">
      </el-alert>
      <article-list :isIndex="false" :honDocuList="item.subList" />
    </div>
    <placeholder :isShow="readList.length===0" title="快去浏览喜欢的内容吧" />
    <div class="more_btn_box loading no_select">
      <span v-if="!noMore" class="more_btn" @click="onMoreReadList">加载更多</span>
      <span v-else>暂无更多</span>
    </div>
  </div>
</template>
<script>
import articleList from '@/components/articleList/index.vue'
import { getRecentRead } from '@/api/user.js'
export default {
  comments: {
    articleList
  },
  data () {
    return {
      readList: [], // 最近阅读数据
      page: {
        pageNum: 1,
        pageSize: 10
      },
      noMore: false
    }
  },
  methods: {
    // 加载更多
    onMoreReadList () {
      this.page.pageNum++
      this.getRecentRead()
    },
    // 获取历史阅读数据
    async getRecentRead () {
      const res = await getRecentRead(this.page)
      this.noMore = res.data.length === 0
      if (this.noMore) return
      const data = res.data.map(item => ({
        ...item,
        subList: this.setKey(item.subList)
      }))
      this.readList = [...this.readList, ...data]
    },
    // 处理key
    setKey (arr) {
      return arr.map(item => {
        const keyArr = Object.keys(item).map(item => (item.split('_').map(item => (item.charAt(0).toUpperCase() + item.slice(1)))).join('')).map(item => (item.charAt(0).toLowerCase() + item.slice(1)))
        const valArr = Object.values(item)
        const obj = {}
        keyArr.forEach((item, i) => {
          obj[item] = valArr[i]
        })
        return obj
      })
    }
  },
  created () {
    // 从本地获取最近阅读数据
    this.getRecentRead()
  }
}
</script>
<style lang="less" scoped>
.read {
  width: 100%;
  .zong {
    font-size: 18px;
    margin-bottom: 20px;
    color: #8b8b8b;
  }
}
.el-alert {
  background-color: #fff;
  /deep/.el-alert__title {
    font-size: 14px;
  }
}
.more_btn_box {
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 16px;
  color: #4b639f;
  .more_btn {
    position: relative;
    top: 0;
    cursor: pointer;
    transition: top 0.3s;
    &:hover {
      top: -1px;
    }
  }
}
.el-alert {
  position: relative;
  background-color: #f5f7fb;
  padding: 0 10px;
  /deep/.el-alert__title {
    font-size: 14px;
  }
}
</style>
